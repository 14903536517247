import React from 'react';
import { Script } from 'gatsby';

export const wrapPageElement = ({ element }) => {
  const ga4MeasurementID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID;

  return (
    <>
      {element}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${ga4MeasurementID}`}
      />
      <Script id="ga4">{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${ga4MeasurementID}');
      `}</Script>
    </>
  );
};
